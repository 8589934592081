import React, { useState, useEffect } from 'react';
import './Message.css';

const Message = ({ message, clearMessage, duration = 3000, fadeOutDuration = 500 }) => {
  const [visible, setVisible] = useState(true);
  const [fadeIn, setFadeIn] = useState(false); // State for fade-in effect
  const [fadeOut, setFadeOut] = useState(false); // State for fade-out effect

  useEffect(() => {
    // Start fade-in effect after the component is mounted
    const fadeInTimer = setTimeout(() => {
      setFadeIn(true);
    }, 10); // Trigger fade-in almost immediately

    // Start fade-out effect before the duration ends
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true); // Trigger fade-out
    }, duration - fadeOutDuration); // Fade-out happens just before the full duration ends

    // Completely clear the message after fade-out
    const clearMessageTimer = setTimeout(() => {
      setVisible(false);
      if (clearMessage) {
        clearMessage(); // Reset the message state after it disappears
      }
    }, duration);

    // Clean up timers on unmount or when duration/message changes
    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(clearMessageTimer);
    };
  }, [clearMessage, duration, fadeOutDuration, message]);

  return (
    visible && (
      <div className={`message-container ${fadeIn ? 'fade-in' : ''} ${fadeOut ? 'fade-out' : ''}`}>
        {message}
      </div>
    )
  );
};

export default Message;
