import React from 'react';
import PropTypes from 'prop-types';
import './Banner.css';

const Banner = ({
  backgroundColor = '#983939',
  height = '60px',
  textColor = '#ffffff',
  text = ' HEEL VEEL ACTIE! - Bekijk nu onze actieproducten! ',
  buttonLabel = 'Bekijk de producten hier!',
  buttonHref = 'https://portaal.thebalux.nl',
  isVisible = true,
}) => {
  if (!isVisible) return null;

  return (
    <div
      style={{
        backgroundColor,
        height,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Bewegende tekst */}
      <div className="scrolling-wrapper">
        <div className="scrolling-text">
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
        </div>
        <div className="scrolling-text">
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
        </div>
        <div className="scrolling-text">
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
        </div>
        <div className="scrolling-text">
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
          <span style={{ color: textColor }}>{text} • </span>
        </div>
      </div>

      {/* Witte statische button met drop shadow */}
      <a href={buttonHref} target='blank' style={{ position: 'absolute', zIndex: 2 }}>
        <button
          className="banner-button"
          style={{
            backgroundColor: '#FFFFFF',
            color: backgroundColor,
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            boxShadow: `0 0 20px 50px rgba(152, 57, 57, 0.8)`,
            fontWeight: 'bold',
          }}
        >
          {buttonLabel}
        </button>
      </a>
    </div>
  );
};

Banner.propTypes = {
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonHref: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default Banner;
