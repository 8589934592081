import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './ImageHero.css';

const ImageHero = ({
  title,
  subtitle,
  imageSrc,
  height = '70vh',
  paddingTop = '0',
  width,
  logo,
  isSvgInline,
  textAlign = 'center', // Tekstuitlijning (left, center, right)
  textColor = '#fff', // Kleur van de tekst
  fontWeight = 'normal', // Letterdikte (e.g., normal, bold, 400, 700)
  letterSpacing = '15px', // Afstand tussen letters
  fontSize = '2rem', // Lettergrootte
  subtitleFontSize = '1rem', // Lettergrootte van de ondertitel
  fade = false, // Fade-effect toevoegen
}) => {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const titleRef = useRef(null);

  const titles = Array.isArray(title) ? title : [title]; // Convert title to array if not already

  useEffect(() => {
    if (titles.length > 1 && !logo) {
      const interval = setInterval(() => {
        gsap.to(titleRef.current, {
          y: -20,
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
            gsap.fromTo(titleRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5 });
          },
        });
      }, 3000); // Switch titles every 3 seconds

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [titles, logo]);

  const widthClass = width === 'narrow' ? 'narrow' : 'full';

  return (
    <section
      className={`herob ${widthClass} ${fade ? 'fade-effect' : ''}`}
      style={{ backgroundImage: `url(${imageSrc})`, height, paddingTop }}
    >
      <div
        className="herob-content"
        style={{
          textAlign,
          color: textColor,
        }}
      >
        {logo ? (
          isSvgInline ? (
            // Render inline SVG if isSvgInline is true
            <div className="hero-logo" dangerouslySetInnerHTML={{ __html: logo }} />
          ) : (
            // Render external image or SVG as a file
            <img src={logo} alt="logo" className="hero-logo" />
          )
        ) : (
          <h1
            ref={titleRef}
            style={{
              fontWeight,
              letterSpacing,
              fontSize,
            }}
          >
            {titles[currentTitleIndex]}
          </h1>
        )}
        <h5 className='subtitle' style={{ fontSize: subtitleFontSize }}>{subtitle}</h5>
      </div>
    </section>
  );
};

export default ImageHero;