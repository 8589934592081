import React from 'react';

// Dynamisch importeren van pagina-componenten
const Home = React.lazy(() => import('../pages/Home'));
const Collecties = React.lazy(() => import('../pages/collecties/Collecties'));
const Exclusief = React.lazy(() => import('../pages/collecties/exclusief/Exclusief'));


const KleurenOverzicht = React.lazy(() => import('../pages/KleurenOverzicht'));
const VerlangLijstje = React.lazy(() => import('../pages/VerlangLijstje'));
const Verkooppunten = React.lazy(() => import('../pages/Verkooppunten'));
const Showroom = React.lazy(() => import('../pages/Showroom'));
const Vacatures = React.lazy(() => import('../pages/Vacatures'));
const VacatureDetail = React.lazy(() => import('../components/VacatureDetail'));
const OverOns = React.lazy(() => import('../pages/over/OverOns'));
const PrivacyStatement = React.lazy(() => import('../pages/PrivacyStatement'));
const Duurzaamheid = React.lazy(() => import('../pages/Duurzaamheid'));
const Ontwikkel = React.lazy(() => import('../components/Ontwikkel'));
const DownloadCV = React.lazy(() => import('../pages/vacatures/DownloadCV'));

const MainRoutes = [
  { path: '/', element: <Home />, theme: 'light', header: 'herotrans' },
  { path: '/collecties', element: <Collecties />, theme: 'light' },
  { path: '/collecties/exclusief', element: <Exclusief />, theme: 'dark' },

  { path: '/kleuren', element: <KleurenOverzicht />, theme: 'dark' },
  { path: '/verlanglijstje', element: <VerlangLijstje />, theme: 'light' },
  { path: '/verkooppunten', element: <Verkooppunten />, theme: 'light' },
  { path: '/showroom', element: <Showroom />, theme: 'light' },


  { path: '/werken-bij', element: <Vacatures />, theme: 'light' },
  { path: '/werken-bij/:slug', element: <VacatureDetail />, theme: 'light' },
  { path: '/downloadcv/:id', element: <DownloadCV />, theme: 'light' },

  { path: '/over-ons', element: <OverOns />, theme: 'dark' },
  { path: '/privacy', element: <PrivacyStatement />, theme: 'light' },
  { path: '/duurzaamheid', element: <Duurzaamheid />, theme: 'light' },
  { path: '/inspiratie', element: <Ontwikkel />, theme: 'light' },
];

export default MainRoutes;
