import React, { useState } from 'react';
import './pdfViewer.css';
import Message from '../Message';

const PdfViewer = ({ imageSrc, title, subText, buttonText, pdfUrl }) => {
  const [message, setMessage] = useState('');
  const [messageDuration, setMessageDuration] = useState(null);

  const handleButtonClick = () => {
    setMessage('PDF wordt gedownload!');
    setMessageDuration(3000);

    // Clear the message after the specified duration
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  return (
    <div className="pdf-viewer-container">
      <div className="pdf-viewer-content">
        <img src={imageSrc} alt="Voorbeeld_pdf" className="pdf-image" />
        <div className="pdf-text-content">
          <h1 className="pdf-title">{title}</h1>
          <p className="pdf-subtext">{subText}</p>
          
          {/* Button with download functionality */}
          <a href={pdfUrl} download onClick={handleButtonClick}>
            <button className="pdf-button">{buttonText}</button>
          </a>
        </div>
      </div>

      {/* Message Component to show the message */}
      {message && (
        <Message
          message={message}
          clearMessage={() => setMessage('')}
          duration={messageDuration}
        />
      )}
    </div>
  );
};

export default PdfViewer;
