import React, { useState, useEffect } from 'react';
import './404.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Page404 = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [secondsLeft, setSecondsLeft] = useState(5);
  const navigate = useNavigate();

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setSecondsLeft((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => {
      clearInterval(countdownInterval);
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <div className="page-404-container">

      <Helmet>
        <title>Thebalux | 404</title>
        <meta name="description" content="De pagina die je zoekt is niet gevonden. Ga terug naar de homepage of bekijk onze producten." />
        <meta name="robots" content="noindex, follow" />
        <meta name="keywords" content="404, pagina niet gevonden, Thebalux, badkamermeubelen" />
        <link rel="canonical" href="https://www.thebalux.nl/" />
      </Helmet>


      <div
        className="error-404"
        onMouseMove={handleMouseMove}
        style={{
          '--mouse-x': `${mousePosition.x}px`,
          '--mouse-y': `${mousePosition.y}px`,
        }}
      >
        404
      </div>
      <h1>Oh nee!! Dit snappen we even niet...</h1>
      <p>De pagina die je probeert te bezoeken bestaat (nog) niet of bestaat niet meer.</p>
      <p>U wordt automatisch terug gestuurd naar de homepage over {secondsLeft} seconden...</p>
      <a href='/'>
        <button>Haal me hier weg</button>
      </a>
    </div>
  );
};

export default Page404;
