import React, { useState } from 'react';
import { FaEnvelope, FaCopy, FaFacebookF, FaTwitter, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import './ShareBanner.css';

const ShareBanner = () => {
  const currentUrl = window.location.href;
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl);
    alert('Link gekopieerd naar klembord!');
  };

  const socialOptions = [
    {
        label: 'Kopieer link',
        icon: <FaCopy />,
        link: null,
        onClick: handleCopyLink,
      },
    {
      label: 'Deel via e-mail',
      icon: <FaEnvelope />,
      link: `mailto:?subject=Interessante pagina&body=Bekijk deze pagina: ${currentUrl}`,
      onClick: null,
    },
   
    {
      label: 'Deel op Facebook',
      icon: <FaFacebookF />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
      onClick: null,
    },
    {
      label: 'Deel op Twitter',
      icon: <FaTwitter />,
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`,
      onClick: null,
    },
    {
      label: 'Deel op LinkedIn',
      icon: <FaLinkedin />,
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`,
      onClick: null,
    },
    {
      label: 'Deel via WhatsApp',
      icon: <FaWhatsapp />,
      link: `https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`,
      onClick: null,
    },
  ];

  return (
    <div className="share-banner">
      <div className="share-banner-container">
        <span className="share-text">Deel deze pagina</span>
        <div className="share-icons">
          {socialOptions.map((option, index) => (
            <div
              key={index}
              className={`share-icon ${hoveredIcon === index ? 'hovered' : ''}`}
              onMouseEnter={() => setHoveredIcon(index)}
              onMouseLeave={() => setHoveredIcon(null)}
            >
              {option.link ? (
                <a
                  href={option.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={option.label}
                >
                  <div className="icon-circle">{option.icon}</div>
                </a>
              ) : (
                <button className="icon-circle" onClick={option.onClick} aria-label={option.label}>
                  <div >{option.icon}</div>
                </button>
              )}
              {/* {hoveredIcon === index && <span className="icon-label">{option.label}</span>} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShareBanner;