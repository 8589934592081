import React from 'react';
import './TextBanner.css';
import { Link } from 'react-router-dom';

const TextBanner = ({ heading, paragraph, buttonText, buttonLink, type }) => {
    return (
        <div className="textinfo">
            <div className='textinfo-inhoud'>
                <h1>{heading}</h1>
                <p>{paragraph}</p>
                <br />

                {buttonText && buttonLink && (
                    type === 'download' ? (
                        <a href={buttonLink} download>
                            <button className="button">{buttonText}</button>
                        </a>
                    ) : (
                        <Link to={buttonLink}>
                            <button className="button">{buttonText}</button>
                        </Link>
                    )
                )}
            </div>
        </div>
    );
}

export default TextBanner;
